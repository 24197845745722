import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import SingleLightbox from './singleLightbox';

const HeadshotLightbox = () => {
  const { headshot } = useStaticQuery(graphql`
    {
      headshot: allFile(
        filter: {
          extension: { regex: "/(jpg)|(jpeg)|(png)/" }
          relativePath: { eq: "withmyart.jpg" }
        }
      ) {
        nodes {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  `);
  return (
    <SingleLightbox
      images={headshot.nodes}
      descriptions={[
        {
          name: 'withmyart',
          alt: 'Me with my poster art design for the final Karaoke Apocalypse show in the wild',
        },
      ]}
    />
  );
};
export default HeadshotLightbox;
