export const bio = [
  {
    name: 'centraltexas',
    alt: 'Road trip fun with a tree in Wimberley, Texas',
  },
  {
    name: 'pancakes',
    alt: 'A particularly satisfying vegan blueberry pancake brunch from 2020',
  },
  {
    name: 'sabrina',
    alt: 'Just my bunny Sabrina being super cute',
  },
];
