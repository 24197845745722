/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';
// import Art from '../components/art';
import NameLogo from '../images/logo.svg';
import Hola from '../images/hola.png';
import HeadshotLightbox from '../components/headshot';
import BioPhotosLightbox from '../components/biophotoslightbox';
import {
  LogoTitle,
  TopNav,
  TopNavContainer,
  Logo,
  Banner,
  Container,
  Content,
  Navbar,
  StyledLink,
  Headline,
  HeadlineContainer,
  Profile,
  BodyContent,
  Video,
  Embed,
  BioContent,
  Bio,
  BioPhotos,
  FloatPhotoRight,
  Footer,
  SkillsList,
  SkillsContainer,
  SkillsWrapper,
} from '../styles/home.styles';
import { Helmet } from 'react-helmet';

const IndexPage = () => (
  <>
    <Helmet>
      <title>Laura Nicole Davila</title>
      <meta
        name="description"
        content="Software engineer, Illustrator, and Animator based in ATX"
      />
    </Helmet>
    <Content>
      <TopNav>
        <TopNavContainer>
          <LogoTitle>
            <Logo src={NameLogo} alt="Laura Nicole Davila" width="300px" />
          </LogoTitle>
          <Navbar>
            <li>
              <StyledLink href="http://www.linkedin.com/pub/laura-nicole-d%C3%A1vila/1b/a60/878">
                linkedin
              </StyledLink>
            </li>
            <li>
              <StyledLink
                href="https://www.imdb.com/name/nm3954898/?ref_=fn_al_nm_1"
                target="_blank"
              >
                IMDB
              </StyledLink>
            </li>
            {/* <li>
          <StyledLink
            href={`/lauranicoledavila_webportfolio_2017.pdf`}
            target="_blank"
          >
            UX Portfolio.PDF
          </StyledLink>
        </li> */}
            <li>
              <StyledLink href="https://vimeo.com/214238146" target="_blank">
                Animation
              </StyledLink>
            </li>
            {/* <li>
          <StyledLink href="./pdf/art_2018.pdf" target="_blank">
            Art Portfolio.PDF
          </StyledLink>
        </li> */}
            <li>
              <StyledLink
                href="http://instagram.com/laurita.luna.draws"
                target="_blank"
              >
                Art Insta
              </StyledLink>
            </li>
            <li>
              <StyledLink href="https://lauritaluna.square.site/s/shop">
                Shop
              </StyledLink>
            </li>
            <li>
              <StyledLink href="https://www.codeoftheheartfilm.com/">
                🌙 The Code of the Heart (Animated Short)
              </StyledLink>
            </li>
          </Navbar>
        </TopNavContainer>
      </TopNav>
      <main>
        <Headline>
          <HeadlineContainer>
            <Profile>
              <h2>Hola!</h2>
              <h3>
                My name is Laura and I'm a Software Engineer, Illustrator, and
                Animator living in Austin, TX!
              </h3>

              <h4>
                Nice to meet you! Drop me a line:{' '}
                <StyledLink href="mailto:lnd337@gmail.com">
                  lnd337@gmail.com
                </StyledLink>
              </h4>
            </Profile>
            <Banner src={Hola} alt="" width="1200" />
          </HeadlineContainer>
        </Headline>

        <BioContent>
          <h2>About me</h2>
          <Bio>
            <Container>
              <p>
                Hi! I love all things art and design related, but also have a
                passion for logic and computer programming. As I keep myself in
                constant student mode, my curiosity and drive helped me succeed
                in my academic career and continue to help me learn new things
                everyday. In my spare time I enjoy drawing, experimenting with
                new vegan recipes, and caring for my bunny, Sabrina.
              </p>
              <FloatPhotoRight>
                <HeadshotLightbox />
              </FloatPhotoRight>
              <p>
                These days I am serving as a Software Engineer at HEB Digital in
                Austin, TX. I've got experience working closely with other
                designers, content writers, and developers to create
                customer-facing digital experiences for a variety of
                organizations and industries. I've done a little bit of
                everything related to web including user experience design,
                mock-up creation, and front-end development.
              </p>

              <p>
                Since my true love has always been cartoons, I also do freelance
                animation, illustration, and graphic design. One of my favorite
                projects from the last few years was creating rotoscope
                animations for{' '}
                <StyledLink href="http://onstory.tv" target="_blank">
                  OnStory
                </StyledLink>
                , an award-winning television series produced by the Austin Film
                Festival. Check your local listings to watch on PBS, or check
                out the archive of episodes online.
              </p>
            </Container>
          </Bio>
          <BioPhotos>
            <BioPhotosLightbox />
          </BioPhotos>
        </BioContent>

        {/* <Art /> */}

        <SkillsWrapper>
          <SkillsContainer>
            <h2>Skills and Experience</h2>
            <p>
              In my time working in the tech and creative industries, I've had
              the pleasure to learn a lot along the way! Here's a quick overview
              of my skillset.
            </p>
            <h3>Engineering 👩🏽‍💻</h3>
            <SkillsList>
              <li>JavaScript</li>
              <li>HTML</li>
              <li>CSS</li>
              <li>git</li>
              <li>React with Hooks</li>
              <li>Graphql</li>
              <li>Gatsby JS</li>
              <li>Styled-components</li>
              <li>Jest</li>
              <li>testing-library</li>
              <li>Vue.js</li>
              <li>Github</li>
              <li>Gitlab</li>
              <li>Jira</li>
              <li>CI/CD</li>
              <li>Storybook</li>
              <li>Php</li>
              <li>Wordpress</li>
              <li>Drupal</li>
            </SkillsList>
            <h3>Design 🖋</h3>
            <SkillsList>
              <li>Prototyping</li>
              <li>Digital design</li>
              <li>Wire framing</li>
              <li>Human centered design</li>
              <li>UX research</li>
              <li>Mock-ups</li>
              <li>Sketch</li>
              <li>Adobe InDesign</li>
              <li>Adobe Illustrator</li>
              <li>Adobe Photoshop</li>
              <li>Empathy mapping</li>
              <li>UI patterns for accessibility</li>
            </SkillsList>
            <h3>Creative 🎨</h3>
            <SkillsList>
              <li>Life drawing</li>
              <li>Photoshop</li>
              <li>Videoediting</li>
              <li>After effects</li>
              <li>Photography</li>
              <li>Titles design for film</li>
              <li>Graphic design</li>
              <li>Motion graphics</li>
              <li>Character design</li>
              <li>Storyboarding</li>
              <li>Production design for film</li>
              <li>2D animation</li>
              <li>Rotoscope animation</li>
            </SkillsList>
          </SkillsContainer>
        </SkillsWrapper>

        <BodyContent>
          <Container>
            <h2>Animation Reel</h2>
            <p>A quick demo reel of some of my favorite animated projects.</p>
            <Video>
              <Embed>
                <iframe
                  src="https://player.vimeo.com/video/214238146"
                  width="640"
                  height="360"
                  frameBorder="0"
                  webkitallowfullscreen="true"
                  mozallowfullscreen="true"
                  allowFullScreen={true}
                  title="Laura Davila 2017 Animation Reel"
                />
              </Embed>
            </Video>
          </Container>
        </BodyContent>
      </main>
      <Footer>
        <Container>
          <h2>Thanks for stopping by!</h2>
          <h3>
            Drop me a line{' '}
            <StyledLink href="mailto:lnd337@gmail.com">
              lnd337@gmail.com
            </StyledLink>{' '}
          </h3>
          <p>
            Made with 💖 in ATX 2021 with{' '}
            <StyledLink href="https://www.gatsbyjs.com" target="_blank">
              GatsbyJS
            </StyledLink>{' '}
            and lots of ☕️
          </p>
        </Container>
      </Footer>
    </Content>
  </>
);

export default IndexPage;
