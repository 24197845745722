import styled from 'styled-components';

export const Content = styled.div`
  color: rgba(255, 255, 255, 0.9);
  font-size: 18px;
  line-height: 1.6em;
`;

export const Container = styled.div`
  display: block;
  margin: auto;
  width: 100%;
  max-width: 1400px;
`;

export const TopNav = styled.div`
  background: #000;
  width: 100%;
  display: block;
  box-sizing: border-box;
  padding: 10px;
`;

export const TopNavContainer = styled.div`
  width: 100%;
  @media (min-width: 769px) {
    display: flex;
    max-width: 1400px;
    margin: auto;
    align-items: center;
  }
`;

export const Headline = styled.header`
  background: linear-gradient(
    180deg,
    rgb(65 18 101) 0%,
    rgb(101 57 123) 73%,
    rgb(232 132 215) 100%
  );
  position: relative;
  &::before {
    content: '';
    display: block;
    height: 28%;
    width: 100%;
    background: #655263;
    position: absolute;
    bottom: 0;
  }
`;

export const Banner = styled.img`
  width: 100vw;
  max-width: 100%;
  height: auto;
  vertical-align: bottom;
`;

export const HeadlineContainer = styled.div`
  position: relative;
  display: block;
  margin: auto;
  width: 100%;
  max-width: 1400px;
`;

export const Profile = styled.div`
  padding: 1em 2em;
  @media (min-width: 769px) {
    position: absolute;
    width: 350px;
    top: 0;
    right: 5%;
  }
  @media (min-width: 1000px) {
    width: 400px;
    right: 10%;
    top: 10%;
  }
  @media (min-width: 1200px) {
    width: 550px;
    right: 10%;
    top: 10%;
    font-size: 1.3em;
    line-height: 1.5em;
  }
`;

export const BodyContent = styled.section`
  padding: 2em;
`;

export const BioContent = styled.section`
  max-width: 960px;
  padding: 2em;
  margin: auto;
  text-align: justify;
`;

export const Bio = styled.div`
  grid-area: bio;
  margin: auto;
`;

export const FloatPhotoRight = styled.div`
  display: block;
  margin: 1em auto;
  width: 220px;
  @media (min-width: 769px) {
    float: right;
    margin-left: 1em;
    margin-top: 0;
  }
`;

export const BioPhotos = styled.div`
  display: flex;
  width: 100%;
  margin: auto;
  justify-content: space-between;
`;

export const LogoTitle = styled.h1`
  margin: 0;
`;

export const Logo = styled.img`
  margin: 10px auto;
  display: block;
  width: 200px;
  @media (min-width: 769px) {
    margin: 0 20px;
    width: 300px;
  }
`;

export const StyledLink = styled.a`
  color: #deb9f2;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: bold;
  &:hover {
    color: rgb(255, 6, 223);
  }
`;

export const Navbar = styled.ul`
  display: block;
  margin: auto;
  text-align: center;
  list-style-type: none;
  font-size: 0.8em;
  margin-left: 0;
  padding-left: 0;
  li {
    display: inline-block;
    margin: 1em;
  }
`;

export const Video = styled.div`
  grid-area: video;
  max-width: 700px;
  width: 80%;
  margin: auto;
`;

export const Embed = styled.div`
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  height: auto;
  iframe,
  object,
  embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

export const Footer = styled.footer`
  text-align: center;
  margin-top: 1em;
  font-size: 0.6em;
  background: #000;
  box-sizing: border-box;
  padding: 1em;
`;

export const SkillsWrapper = styled.section`
  background: #000;
`;

export const SkillsList = styled.ul`
  padding-left: 0;
  column-count: 2;
  list-style-type: none;
  margin: 1em auto;
  @media (min-width: 769px) {
    column-count: 3;
  }
`;

export const SkillsContainer = styled.div`
  max-width: 960px;
  padding: 2em;
  margin: auto;
`;
