import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Lightbox from './lightbox';
import { bio } from '../images/bio/bio';

const BioPhotosLightbox = () => {
  const { bioImages } = useStaticQuery(graphql`
    query {
      bioImages: allFile(
        filter: {
          extension: { regex: "/(jpg)|(jpeg)|(png)/" }
          relativeDirectory: { eq: "bio" }
        }
      ) {
        nodes {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  `);
  return <Lightbox images={bioImages.nodes} descriptions={bio} />;
};
export default BioPhotosLightbox;
