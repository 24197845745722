import React, { useState } from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import { Dialog } from '@reach/dialog';

const PreviewButton = styled.button`
  background: transparent;
  border: none;
  padding: 0;
  margin: 0;
`;

const Caption = styled.p`
  color: #fff;
  position: absolute;
  text-align: center;
  background: rgba(0, 0, 0, 0.8);
  bottom: 0;
  padding: 1em;
  width: 100%;
  box-sizing: border-box;
`;

const DisplayImage = styled.div`
  position: relative;
  box-sizing: border-box;
  height: 100vh;
`;

const SingleLightbox = ({ images, descriptions = [] }) => {
  const [showLightbox, setShowLightbox] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');
  const [selectedAlt, setSelectedAlt] = useState('');

  return (
    <>
      {images.map((image, key) => {
        const fileName = getImage(image).images?.fallback?.src;
        const { alt } =
          descriptions.find((description) =>
            fileName.includes(description.name),
          ) || '';
        return (
          image && (
            <PreviewButton
              key={key}
              type="button"
              onClick={() => {
                setShowLightbox(true);
                setSelectedImage(image);
                setSelectedAlt(alt);
              }}
            >
              <GatsbyImage
                image={getImage(image)}
                alt={alt}
                layout="fullWidth"
              />
            </PreviewButton>
          )
        );
      })}
      {showLightbox && (
        <Dialog
          isOpen={showLightbox}
          onDismiss={() => setShowLightbox(false)}
          aria-label={selectedAlt}
        >
          <DisplayImage>
            <GatsbyImage
              onClick={() => setShowLightbox(false)}
              image={getImage(selectedImage)}
              alt={selectedAlt}
              placeholder="blurred"
              objectFit="scale-down"
              style={{ height: '100vh', width: 'auto' }}
            />
            <Caption>{selectedAlt}</Caption>
          </DisplayImage>
        </Dialog>
      )}
    </>
  );
};

export default SingleLightbox;
